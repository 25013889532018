<template>
  <div class="app-wrapper" :style="{ paddingBottom: token ? '75px' : '' }">
    <router-view
      class="router-view"
      :class="{ logined: token }"
      v-slot="{ Component }"
    >
      <transition name="fade" mode="out-in">
        <Component :is="Component" />
      </transition>
    </router-view>
    <transition name="navbar">
      <Navbar v-if="token" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/shared/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters({ token: "User/_token" }),
  },
  created() {
    this.$store.dispatch("User/CHECK_LOCALE_TOKEN");
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this.token) {
        return next({ name: "LoginRoute" });
      } else if (to.meta.auth && this.token) {
        return this.$store
          .dispatch("User/AUTH")
          .then(() => next())
          .catch((err) => {
            next({ name: "LoginRoute" });
            this.$swal({
              icon: "error",
              text: err?.message ?? err,
            });
          });
      } else if (
        (to.name === "LoginRoute" || to.name === "RegisterRoute") &&
        this.token
      ) {
        next(from);
      } else {
        next();
      }
    });
  },
};
</script>

<style lang="scss">
.router-view {
  padding: 0 0.5rem;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-to {
  transition: all 150ms ease-in-out;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.navbar-enter-from,
.navbar-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.navbar-enter-active,
.navbar-leave-to {
  transition: all 300ms ease-in-out;
}
.navbar-enter-to,
.navbar-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
