import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomeRoute",
    component: () => import("@/views/HomeView"),
  },
  {
    path: "/register",
    name: "RegisterRoute",
    component: () => import("@/views/RegisterView"),
  },
  {
    path: "/login",
    name: "LoginRoute",
    component: () => import("@/views/LoginView"),
  },
  {
    path: "/dashboard",
    name: "DashboardRoute",
    component: () => import("@/views/DashboardView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/lesson/:lessonId",
    name: "LessonRoute",
    component: () => import("@/views/LessonView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/card",
    name: "CardRoute",
    component: () => import("@/views/CardView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile",
    name: "ProfileRoute",
    component: () => import("@/views/ProfileView"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
