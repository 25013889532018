import axios from "axios";

export default {
  namespaced: true,
  state: {
    list: [],
    completed_list: [],
    list_loading: null,
    completed_list_loading: null,
    exam_popup: null,
  },
  getters: {
    _list(state) {
      return state.list;
    },
    _completed_list(state) {
      return state.completed_list;
    },
    _list_loading(state) {
      return state.list_loading;
    },
    _completed_list_loading(state) {
      return state.completed_list_loading;
    },
    _exam_popup(state) {
      return state.exam_popup;
    },
  },
  mutations: {
    setList(state, payload) {
      state.list = payload;
    },
    setCompletedList(state, payload) {
      state.completed_list = payload;
    },
    pushCompletedList(state, payload) {
      state.completed_list.push(payload);
    },
    setListLoading(state, payload) {
      state.list_loading = payload;
    },
    setCompletedListLoading(state, payload) {
      state.completed_list_loading = payload;
    },
    setExamPopup(state, payload) {
      state.exam_popup = payload;
    },
  },
  actions: {
    LIST(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setListLoading", true);
        setTimeout(() => {
          axios
            .get(`/api/exam/list`, {
              params: { topic: data },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
              },
            })
            .then((response) => {
              context.commit("setList", response.data.data.list);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response?.data ?? error);
            })
            .finally(() => {
              context.commit("setListLoading", false);
            });
        }, 500);
      });
    },
    COMPLETED_LIST(context) {
      return new Promise((resolve, reject) => {
        context.commit("setCompletedListLoading", true);
        axios
          .get(`/api/exam/completed-list`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
          })
          .then((response) => {
            context.commit("setCompletedList", response.data.data.list);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setCompletedListLoading", false);
          });
      });
    },
    COMPLETE(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/api/exam/complete`,
            { exam_id: data.id, answer: data.answer },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
              },
            }
          )
          .then((response) => {
            context.commit(
              "pushCompletedList",
              response.data.data.completed_lesson
            );
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response?.data ?? error);
          });
      });
    },
  },
};
