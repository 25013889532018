import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    register_loading: null,
    login_loading: null,
  },
  getters: {
    _token(state) {
      return state.token;
    },
    _user(state) {
      return state.user;
    },
    _register_loading(state) {
      return state.register_loading;
    },
    _login_loading(state) {
      return state.login_loading;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setRegisterLoading(state, payload) {
      state.register_loading = payload;
    },
    setLoginLoading(state, payload) {
      state.login_loading = payload;
    },
  },
  actions: {
    REGISTER(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setRegisterLoading", true);
        axios
          .post(`/api/user/register`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setRegisterLoading", false);
          });
      });
    },
    LOGIN(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoginLoading", true);
        axios
          .post(`/api/user/login`, data)
          .then((response) => {
            localStorage.setItem("TOKEN", response.data.data.token);
            context.commit("setToken", response.data.data.token);
            context.commit("setUser", response.data.data.user);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setLoginLoading", false);
          });
      });
    },
    CHECK_LOCALE_TOKEN(context) {
      if (!localStorage.getItem("TOKEN")) return;
      context.commit("setToken", localStorage.getItem("TOKEN"));
    },
    AUTH(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/user/auth`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((response) => {
            context.commit("setUser", response.data.data.user);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit("setToken", null);
            context.commit("setUser", null);
            localStorage.removeItem("TOKEN");
            reject(error?.response?.data ?? error);
          });
      });
    },
    LOGOUT(context) {
      return new Promise((resolve) => {
        context.commit("setToken", null);
        context.commit("setUser", null);
        localStorage.removeItem("TOKEN");
        resolve();
      });
    },
  },
};
