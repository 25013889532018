<template>
  <nav class="navbar">
    <ul>
      <li>
        <router-link
          :to="{ name: 'DashboardRoute' }"
          exact-active-class="active"
        >
          <i class="bi bi-journal-bookmark"></i>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'CardRoute' }" exact-active-class="active">
          <i class="bi bi-star"></i>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'ProfileRoute' }" exact-active-class="active">
          <i class="bi bi-person"></i>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "AppNavbar",
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  right: 0;
  height: 75px;
  background-color: var(--secondary);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  box-shadow: 2px 4px 14px -2px rgba(0, 0, 0, 0.1);
  ul {
    list-style: none;
    height: 100%;
    width: 100%;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: var(--secondary);
        color: white;
        font-size: 1.15rem;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        &.active {
          background-color: var(--primary);
        }
      }
    }
  }
}
</style>
