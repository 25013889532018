import { createStore } from "vuex";
import User from "./modules/User";
import Topic from "./modules/Topic";
import Lesson from "./modules/Lesson";
import Exam from "./modules/Exam";
import Card from "./modules/Card";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    User,
    Topic,
    Lesson,
    Exam,
    Card,
  },
});
