import axios from "axios";

export default {
  namespaced: true,
  state: {
    list: [],
    list_loading: false,
  },
  getters: {
    _list(state) {
      return state.list;
    },
    _list_loading(state) {
      return state.list_loading;
    },
  },
  mutations: {
    setList(state, payload) {
      state.list = payload;
    },
    setListLoading(state, payload) {
      state.list_loading = payload;
    },
  },
  actions: {
    LIST(context) {
      return new Promise((resolve, reject) => {
        context.commit("setListLoading", true);
        axios
          .get(`/api/card/list`, {
            params: { withLessons: 1 },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
            },
          })
          .then((response) => {
            context.commit("setList", response.data.data.list);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response?.data ?? error);
          })
          .finally(() => {
            context.commit("setListLoading", false);
          });
      });
    },
  },
};
