import { createApp } from "vue";
import App from "./App";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import axios from "axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "@/assets/scss/bootstrap.scss";
import "@/assets/scss/main.scss";

axios.defaults.baseURL = process.env.VUE_APP_BASE ?? `http://localhost:3000`;

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2, {
  cancelButtonText: "İptal",
  confirmButtonText: "Tamam",
});
app.mount("#app");
